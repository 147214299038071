<template>
  <el-form :inline="true" :model="personnelData" :rules="rules" ref="userForm" label-width="130px">
    <el-form-item label="所属楼层" prop="FLOOR_ID" class="form-col">
      <el-cascader style="width: 100%;" v-model="personnelData.FLOOR_ID" :props="treeProps" clearable @change="toRoleDep($event)" :options="treeData">
      </el-cascader>
    </el-form-item>
    <el-form-item label="产权人:" prop="property_name" class="form-col">
      <el-input v-model="personnelData.property_name" />
    </el-form-item>
    <el-form-item label="登记日期:" prop="register" class="form-col">
      <el-date-picker v-model="personnelData.register" type="date" placeholder="请输入日期" format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width:100%;"/>
    </el-form-item>
    <el-form-item label="联系电话:" prop="property_name" class="form-col">
      <el-input v-model="personnelData.householderPhone" />
    </el-form-item>
    <el-form-item label="房屋产权:" prop="property" class="form-col">
      <el-checkbox-group v-model="personnelData.property">
        <el-checkbox label="自有私房"></el-checkbox>
        <el-checkbox label="租赁私房"></el-checkbox>
        <el-checkbox label="租赁公房"></el-checkbox>
        <el-checkbox label="借往"></el-checkbox>
        <el-checkbox label="公租房"></el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="房屋状态:" prop="state" class="form-col">
      <el-checkbox-group v-model="personnelData.state">
        <el-checkbox label="在住"></el-checkbox>
        <el-checkbox label="退房"></el-checkbox>
        <el-checkbox label="已故"></el-checkbox>
        <el-checkbox label="其他"></el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="家庭类型:" prop="familyType" class="form-row">
      <el-checkbox-group v-model="personnelData.familyType">
        <el-checkbox label="常住户"></el-checkbox>
        <el-checkbox label="流动户"></el-checkbox>
        <el-checkbox label="享受公租房"></el-checkbox>
        <el-checkbox label="空房户"></el-checkbox>
        <el-checkbox label="低保户"></el-checkbox>
        <el-checkbox label="低收入"></el-checkbox>
        <el-checkbox label="独生子女家庭"></el-checkbox>
        <el-checkbox label="失独家庭"></el-checkbox>
        <el-checkbox label="残疾人家庭"></el-checkbox>
        <el-checkbox label="空巢户"></el-checkbox>
        <el-checkbox label="享受廉租住房补贴"></el-checkbox>
        <el-checkbox label="享受廉租住房"></el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item class="form-row">
      <el-divider content-position="left">详细情况</el-divider>
    </el-form-item>
    <!-- 人员表 -->
    <el-form-item class="form-row">
      <el-button type="primary" @click="infoAdd">新增</el-button>
      <el-table :data="personnelData.communityInfo">
        <el-table-column prop="personnel_name" label="姓名" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.personnel_name" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="birth" label="出生日期" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.birth" style="width: 100px;" clearable placeholder="YYYY-MM-DD"/>
          </template>
        </el-table-column>
        <el-table-column prop="sex" label="性别" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.sex" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="nation" label="民族" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.nation" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="card" label="身份证号码" width="200">
          <template #default="scope">
            <el-input v-model="scope.row.card" style="width: 180px;" clearable @change="getDatatimeChange(scope.row)"/>
          </template>
        </el-table-column>
        <el-table-column prop="landscape" label="政治面貌" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.landscape" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="residence" label="户籍所在地" width="140">
          <template #default="scope">
            <el-input v-model="scope.row.residence" style="width: 120px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="health" label="健康状况" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.health" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="culture" label="文化程度" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.culture" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="religious" label="宗教信仰" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.religious" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.phone" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="working" label="工作单位" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.working" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="marriage" label="婚姻状况" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.marriage" style="width:100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="marriageTime" label="初（再）婚时间" width="130">
          <template #default="scope">
            <el-input v-model="scope.row.marriageTime" style="width: 110px;" clearable placeholder="YYYY-MM-DD"/>
          </template>
        </el-table-column>
        <el-table-column prop="childTime" label="独生子女证时间" width="150">
          <template #default="scope">
            <el-input v-model="scope.row.childTime" style="width: 120px;" clearable placeholder="YYYY-MM-DD"/>
          </template>
        </el-table-column>
        <el-table-column prop="childNum" label="生育子女数" width="130">
          <template #default="scope">
            <el-input v-model="scope.row.childNum" style="width: 110px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="employment" label="就业情况" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.employment" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="workers" label="职工医保" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.workers" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="society" label="社会养老保险" width="150">
          <template #default="scope">
            <el-input v-model="scope.row.society" style="width: 130px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="resident" label="居民养老保险" width="150">
          <template #default="scope">
            <el-input v-model="scope.row.resident" style="width: 130px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="residentInsurance" label="居民医保" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.residentInsurance" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="advanced" label="高龄补贴" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.advanced" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="disability_level" label="残疾级别" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.disability_level" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="disability_type" label="残疾类型" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.disability_type" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="disability_number" label="残疾证号" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.disability_number" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="enlistment" label="入伍时间" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.enlistment" style="width: 100px;" clearable placeholder="YYYY-MM-DD"/>
          </template>
        </el-table-column>
        <el-table-column prop="retirement" label="退伍时间" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.retirement" style="width: 100px;" clearable placeholder="YYYY-MM-DD"/>
          </template>
        </el-table-column>
        <el-table-column prop="JoinWar" label="是否参战" width="120">
          <template #default="scope">
            <el-input v-model="scope.row.JoinWar" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column prop="military_disability_type" label="伤残类型（级别）" width="150">
          <template #default="scope">
            <el-input v-model="scope.row.military_disability_type" style="width: 100px;" clearable />
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template #default="scope">
            <el-button type="danger" text="danger" @click="infoDelete(scope)" v-if="personnelData.mark !== '3'">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item label="特殊人群情况:" prop="specialName" class="form-row" label-width="150">
      <el-input v-model="personnelData.specialName" :rows="2" type="textarea" placeholder="涉毒、帮教、邪教、两劳释放等情况说明" />
    </el-form-item>
    <el-form-item label="对社区建设意见建议:" prop="opinion" class="form-row" label-width="150">
      <el-input v-model="personnelData.opinion" :rows="2" type="textarea" placeholder="对社区建设意见建议" />
    </el-form-item>
    <el-form-item label="备注:" prop="remarks" class="form-row" label-width="150">
      <el-input v-model="personnelData.remarks" :rows="2" type="textarea" placeholder="备注" />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    personnelData: {
      type: Object,
      default: () => { }
    },
    treeData: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      checkList: [],
      rules: {
        organid: [{ required: true, message: "请选择所属机构", trigger: "blur" }],
        userrole: [{ required: true, message: "请选择用户角色", trigger: "blur" }],
      },
      treeProps: {
        label: 'text', value: 'value', children: 'children',
      },
    }
  },
  mounted () {
  },
  methods: {
    toRoleDep (data) {
      console.log(data);
      if (data === null) {
        this.personnelData.FLOOR_ID = ""
        return
      }
      if (data.length <= 3) {
        this.personnelData.FLOOR_ID = ""
        this.$alert("请选择正确的楼层")
        return
      }
      this.personnelData.FLOOR_ID = data[4]
      console.log(this.personnelData.FLOOR_ID);
    },
    // 获取出生日期
    getDatatimeChange(val){
      if (val.card.length >= 18) {
        val.birth = val.card.substring(6, 10) + "-" + val.card.substring(10, 12) + "-" + val.card.substring(12, 14)
      }
      // this.personnelData.communityInfo.forEach((item)=>{
      //   console.log(item);
      // })
      // console.log(val.substring(6, 10));
      // console.log(val.substring(10, 12));
      // console.log(val.substring(12, 14));
    },
    // 人员表新增
    infoAdd () {
      this.personnelData.communityInfo.push({
        personnel_name: "",
        sex: "",
        nation: "",
        birth:"",
        mark: "1",
        card: "",
        landscape: "",
        residence: "",
        health: "",
        culture: "",
        religious: "",
        phone: "",
        working: "",
        marriage: "",
        marriageTime: "",
        childTime: "",
        childNum: "",
        employment: "",
        workers: "",
        society: "",
        resident: "",
        residentInsurance: "",
        advanced: "",
        disability: "",
        disability_level: "",
        disability_type: "",
        disability_number: "",
        enlistment: "",
        retirement: "",
        JoinWar: "",
        military_disability_type: "",
      })
    },
    // 人员表删除
    infoDelete (scope) {
      if (scope.row.propertyid) {
        this.$confirm("确认删除此类容?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/user/delCommunity", { personnel_id: scope.row.personnel_id })
            .then((response) => {
              if (response.data.success) {
                this.$message({ message: "删除成功", type: "success" })
                this.personnelData.communityInfo.splice(scope.$index, 1)
              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
        })
      } else {
        this.personnelData.communityInfo.splice(scope.$index, 1)
      }
    },
  }
}
</script>